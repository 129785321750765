import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'individual', 'allVirtualTypes', 'allNonVirtualTypes']

  connect() {
    this.toggle()
  }

  toggle() {
    let shown_element = this.selectTarget.value
    this.individualTarget.classList.toggle('d-none', shown_element != 'individual')
    this.allVirtualTypesTarget.classList.toggle('d-none', shown_element != 'all_virtual_types')
    this.allNonVirtualTypesTarget.classList.toggle('d-none', shown_element != 'all_non_virtual_types')
  }
}
