import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.element.addEventListener('change', this.submit)
    this.element.addEventListener('keyup', this.delay)
  }

  submit = () => {
    const form = this.element.closest('form')
    if (form) {
      form.requestSubmit()
    }
  }

  delay = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.submit, 1000)
  }
}
