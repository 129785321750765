import {Controller} from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ['checkbox', 'input', 'hint']

  connect() {
  }

  toggle(event) {
    this.hintTarget.classList.toggle('d-none', !this.checkboxTarget.checked)
    if (this.checkboxTarget.checked) {
      this.inputTarget.required = false
      this.inputTarget.disabled = true
    } else {
      this.inputTarget.required = true
      this.inputTarget.disabled = false
    }
  }
}
