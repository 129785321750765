$(document).on('turbo:load', function () {
  $('#team_name').keyup(function (evt) {
    $('#team_subdomain').val(evt.target.value.toLowerCase().replace(/\s+/g, ''))
  })
})

$(document).on('turbo:load', function () {
  var titles = document.getElementsByClassName('faq_wrap')
  for (i = 0; i < titles.length; i++) {
    titles[i].addEventListener('click', function () {
      $(this).children().next().toggle('fast')
    })
  }
})

$(document).on('turbo:load', function () {
  $('#faq_search').on('keyup', function () {
    var value = $(this).val().toLowerCase()
    $('.faq_title').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    })
  })
})
