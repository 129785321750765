//Toggle Details for Group Table
$(document).on('turbo:load', function () {
  rows = document.getElementsByClassName('group_row')
  if (rows) {
    for (i = 0; i < rows.length; i++) {
      rows[i].addEventListener('click', function () {
        if (this.nextElementSibling.style.display === 'none') {
          for (j = 0; j < rows.length; j++) {
            rows[j].classList.remove('active_row')
            rows[j].nextElementSibling.style.display = 'none'
          }
          this.nextElementSibling.style.display = 'table-row'
          this.classList.add('active_row')
        } else {
          this.nextElementSibling.style.display = 'none'
          this.classList.remove('active_row')
        }
      })
    }
  }
})
