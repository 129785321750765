import {Controller} from '@hotwired/stimulus'
import {Modal} from 'bootstrap'

export default class extends Controller {
  static values = {open: Boolean}

  connect() {
    this.modal = new Modal(this.element)

    if (this.openValue) {
      this.modal.show()
    }
  }
}
