import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['info']

  showInfo(event) {
    this.user_id = event.target.value
    this.url = "/users/" + this.user_id + "/user_info"
    this.sendRequest()
  }

  sendRequest = () => {
    fetch(this.url)
      .then(response =>
        response.json()
      )
      .then((data) => {
        this.showData(data)
      })
  }

  showData(data) {
    if (data['comment'] != undefined) {
      this.infoTarget.innerText = data['name'] + ": " + data['comment'];
      this.infoTarget.classList.remove('d-none');
    } else {
      this.infoTarget.innerText = '';
      this.infoTarget.classList.add('d-none');
    }
  }
}
