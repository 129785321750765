import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.initTomSelect()
  }

  initTomSelect = () => {
    let settings = {
      plugins: {
        remove_button: {
          title: 'Dieses Element entfernen',
        },
      },
    }

    if (this.element.dataset.create) {
      settings.create = true
    }

    if (this.element.dataset.dropdown) {
       var yetAnotherObject = {dropdown_input: {}};
      settings['plugins'] = yetAnotherObject;
    }

    if (this.element.dataset.duplicates) {
      settings.duplicates = true
      settings.hideSelected = false
    }

    new TomSelect(this.element, settings)
  }
}
