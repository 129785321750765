$(document).on('turbo:load', function () {
  $('#searchInputType').on('keyup', function () {
    var value = $(this).val().toLowerCase()
    $('.flex_item').filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    })
  })
})

$(document).on('turbo:load', function () {
  $('#typeGroupSwitch').click(function () {
    if ($(this).prop('checked')) {
      $('#type_quantity').show()
    } else {
      $('#type_quantity').hide()
    }
  })
})

$(document).on('turbo:load', function () {
  $('#clickme').click(function () {
    $('.sub_bg').toggle('slow', function () {})
  })
})
