import {Controller} from '@hotwired/stimulus'
import {German} from 'flatpickr/dist/l10n/de'

export default class extends Controller {
  static targets = ['startTime', 'startDate', 'endDate', 'endTime', 'currentTime']
  static values = {
    format: String,
    enableTime: Boolean,
    noCalendar: Boolean,
    disabledDays: Array,
    minDate: String,
    editMode: String,
    onlyEnd: Boolean,
  }

  async connect() {
    this.type_ids = $('#booking_type_ids').val()
    document.getElementById('booking_type_ids').addEventListener('change', this.newTypeIds)

    if (this.type_ids != "") {
      await this.initializeAll()
      await this.setHandlers()
    }
  }

  newTypeIds = () => {
    this.type_ids = $('#booking_type_ids').val()
    if (this.type_ids != "") {
       this.initializeAll()
       this.setHandlers()
    }
  }

  disconnect() {
  }

  async initializeAll() {
    this.partially_available_dates = await this.fetchPartiallyAvailableDatesForStartDateMonth()

    if (this.hasStartDateTarget) {
      const daysWithLowAvailability = this.partially_available_dates;

      this.startDateInstance = this.initializeDatePicker(this.startDateTarget, {minDate: this.minDateValue,
        onDayCreate: function(dObj, dStr, fp, dayElem) {
          const date = String(dayElem.dateObj.toISOString().slice(0, 10));
          if (daysWithLowAvailability.includes(date)) {
            dayElem.classList.add("low-availability");
          }
        }
    })
      this.fetchDisabledDatesForStartDateMonth()
      this.selectedStartDate = this.startDateInstance.selectedDates[0]
      this.startTimeInstance = this.initializeTimePicker(this.startTimeTarget, {})
    } else if(this.hasCurrentTimeTarget) {
      this.selectedStartDate = this.currentTimeTarget.innerHTML
    }

    this.fetchStartTimeRange()

    this.endDateInstance = this.initializeDatePicker(this.endDateTarget, {
      minDate: this.selectedStartDate,
      maxDate: this.maxDate
    })
    this.selectedEndDate = this.endDateInstance.selectedDates[0]
    this.fetchDisabledDatesForEndDateMonth()

    this.endTimeInstance = this.initializeTimePicker(this.endTimeTarget, {})
    this.fetchEndTimeRange()
  }

  setHandlers() {
    this.startDateInstance
    this.startDateInstance.config.onMonthChange.push(this.fetchDisabledDatesForStartDateMonth)
    if (this.selectedStartDate !== undefined) {
      this.startDateInstance.config.onChange.push(this.updateStartTime)
    }

    if (this.selectedEndDate !== undefined) {
      this.endDateInstance.config.onMonthChange.push(this.fetchDisabledDatesForEndDateMonth)
      this.endDateInstance.config.onChange.push(this.updateEndTime)
    }
  }

  initializeDatePicker(element, options) {
    return flatpickr(element, {
      locale: German,
      altInput: true,
      enableTime: false,
      noCalendar: false,
      altFormat: 'D, j. F Y',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      ...options,
    })
  }

  initializeTimePicker(element, options) {
    return flatpickr(element, {
      locale: German,
      altInput: true,
      enableTime: true,
      noCalendar: true,
      altFormat: 'H:i',
      dateFormat: 'H:i',
      time_24hr: true,
      minuteIncrement: 15,
      minTime: this.minTime,
      maxTime: this.maxTime,
      disableMobile: true,
      onChange(dstr, dobjs, fp) {
        setTimeout(() => {
          const d = fp.latestSelectedDateObj;
          const mins = d.getMinutes();
          if (mins % 15)
            d.setMinutes(15 * Math.round(d.getMinutes() / 15));
          fp.setDate(d, false);
        }, 1000)
      },
      ...options,
    })
  }

  setDisabledDates(data, instance) {
    instance.set('disable', data.unavailable_dates)
  }

  setStartTimeRange = (data, target) => {
    let tmpStartDate = new Date(this.selectedStartDate)
    this.maxDate = new Date(tmpStartDate.setHours(
      tmpStartDate.getHours() + parseInt(data.max_duration)
    ))
    if (this.endDateInstance) {
      this.endDateInstance.set('maxDate', this.maxDate)
    }

    this.max_duration = data.max_duration
    let options = {
      defaultHour: data.start_hour,
      minTime: data.start_hour + ':' + data.start_minute,
      maxTime: data.end_hour + ':' + data.end_minute
    }
    if (this.editModeValue != 'true') {
      target.value = options.minTime
    }
    if (this.hasStartTimeTarget) {
      this.startTimeInstance = this.initializeTimePicker(target, options)
    }
  }

  setEndTimeRange(data, target) {
    let options = {
      defaultHour: data.start_hour,
      minTime: data.start_hour + ':' + data.start_minute,
      maxTime: data.end_hour + ':' + data.end_minute,
    }
    if (this.editModeValue != 'true') {
      target.value = options.minTime
    }
    this.endTimeInstance = this.initializeTimePicker(target, options)
  }

  updateStartTime = () => {
    this.selectedStartDate = this.startDateInstance.selectedDates[0]
    this.fetchStartTimeRange()
    this.endDateInstance.set('minDate', this.selectedStartDate)
  }

  updateEndTime = () => {
    this.selectedEndDate = this.endDateInstance.selectedDates[0]
    this.fetchEndTimeRange()
  }

  fetchStartTimeRange() {
    fetch('/start_time/?start_date=' + this.selectedStartDate + '&type_id=' + this.type_ids)
      .then((response) => response.json())
      .then((data) => {
        if(this.hasStartTimeTarget) {
          this.setStartTimeRange(data, this.startTimeTarget)
        }
      })
  }

  fetchEndTimeRange() {
    if (this.selectedEndDate != null) {
      fetch('/end_time/?end_date=' + this.selectedEndDate + '&type_id=' + this.type_ids)
        .then((response) => response.json())
        .then((data) => this.setEndTimeRange(data, this.endTimeTarget))
    }
  }

  fetchDisabledDatesForStartDateMonth = () => {
    fetch('/unavailable_dates/?current_month=' + (this.startDateInstance.currentMonth + 1) +
      '&current_year=' + this.startDateInstance.currentYear +
      '&type_id=' + this.type_ids)
      .then((response) => response.json())
      .then((data) => this.setDisabledDates(data, this.startDateInstance))
  }

  fetchPartiallyAvailableDatesForStartDateMonth = () => {
    return fetch('/unavailable_dates?current_month=10' +
      '&current_year=2024' +
      '&type_id=' + this.type_ids)
      .then((response) => response.json())
      .then((data) => {return data.partially_available_dates})
  }

  fetchDisabledDatesForEndDateMonth = () => {
    fetch('/unavailable_dates/?current_month=' + (this.endDateInstance.currentMonth + 1) +
      '&current_year=' + this.endDateInstance.currentYear +
      '&type_id=' + this.type_ids)
      .then((response) => response.json())
      .then((data) => this.setDisabledDates(data, this.endDateInstance))
  }

  addHoursToDate(date, hours) {
    return date.setTime(date.getTime() + hours * 60 * 60 * 1000)
  }

  toggle(event) {
    event.target.closest('.input-group').querySelector('.form-control')._flatpickr.toggle()
  }
}
