import { Controller } from '@hotwired/stimulus'

function nearestToggleGroup(input) {
  return input.closest('[data-controller*=toggle-group]')
}

export default class extends Controller {
  static targets = ['toggle']

  connect() {
    document
      .querySelectorAll(
        'select[data-toggle-group], input[data-toggle-group][checked="checked"]'
      )
      .forEach((el) => el.dispatchEvent(new Event('change')))
  }

  toggle(event) {
    const toggleGroup = event.target.dataset.toggleGroup
    let value = event.target.value || event.target.dataset.value

    if (event.target.type == 'checkbox' && !event.target.checked) {
      value = '0'
    }

    this.toggleTargets
      .filter((input) => input.dataset.toggleGroup == toggleGroup)
      .forEach((el, i) => {
        if (
          el.dataset.if === value ||
          (el.dataset.unless !== undefined && el.dataset.unless !== value)
        ) {
          el.classList.remove('d-none')
          for (const input of el.querySelectorAll('input, select, textarea')) {
            if (nearestToggleGroup(input) !== this.element) continue

            input.disabled = false
          }
        } else {
          el.classList.add('d-none')
          for (const input of el.querySelectorAll('input, select, textarea')) {
            if (nearestToggleGroup(input) !== this.element) continue

            input.disabled = true
          }
        }
      })

    if (event.target.dataset.hideOnToggle === 'true') {
      event.target.classList.add('d-none')
    }
  }
}
