$(document).on('turbo:load', function () {
  if (document.querySelector('.custom-file')) {
    document
      .querySelector('.custom-file')
      .addEventListener('change', function (e) {
        var fileName = document.getElementById('inputGroupFile01').files[0].name
        var nextSibling = e.target.nextElementSibling
        nextSibling.innerText = fileName
      })
  }
})

function validateInvitationForm() {
  var approved_domains = $('#approved_domains').html()
  var mail = $('#user_email').val()
  var domain = mail.substring(mail.lastIndexOf('@') + 1)

  if (approved_domains.includes(domain)) {
    return true
  } else {
    alert(
      'Die E-Mail Adresse entspricht nicht den angeforderten Kriterien. \n \nErlaubte E-Mail Domains: ' +
        approved_domains
    )
    return false
  }
}
