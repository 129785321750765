
$(document).on('turbo:load', function () {
  const $dropdown = $('.dropdown')
  const $dropdownToggle = $('.dropdown-toggle')
  const $dropdownMenu = $('.dropdown-menu')
  const showClass = 'show'

  $(window).on('turbo:load resize', function () {
    if (this.matchMedia('(min-width: 768px)').matches) {
      $dropdown.hover(
        function () {
          const $this = $(this)
          $this.addClass(showClass)
          $this.find($dropdownToggle).attr('aria-expanded', 'true')
          $this.find($dropdownMenu).addClass(showClass)
        },
        function () {
          const $this = $(this)
          $this.removeClass(showClass)
          $this.find($dropdownToggle).attr('aria-expanded', 'false')
          $this.find($dropdownMenu).removeClass(showClass)
        }
      )
    } else {
      $dropdown.off('mouseenter mouseleave')
    }
  })
})
