import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static values = {text: String, title: String, autohide: Boolean, variant: String, onclick: Boolean}

  connect() {
    this.timeout = this.autohideValue ? 5000 : 0
    this.onclick = this.onclickValue ? this.onclickValue : false

    if (this.variantValue === 'warning') {
      toastr.warning(this.textValue, this.titleValue, {timeOut: this.timeout, onclick: this.onclick})
    } else if (this.variantValue === 'info') {
      toastr.info(this.textValue, this.titleValue, {timeOut: this.timeout, onclick: this.onclick})
    } else {
      toastr.info(this.textValue, this.titleValue, {timeOut: this.timeout, onclick: this.onclick})
    }
  }
}
