import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static target = ['input']

  connect() {
    this.settings = {
      plugins: {
        remove_button: {
          title: 'Dieses Element entfernen',
        },
      },
    }

    this.initializeByClass()
  }

  inputTargetConnected(el) {
    if (el.dataset.disableRemoval === 'true') {
      this.settings.plugins = {}
    }

    new TomSelect(el, this.settings)
  }

  initializeByClass() {
    document.querySelectorAll('.tom-select').forEach((el) => {
      if (el.dataset.disableRemoval === 'true') {
        this.settings.plugins = {}
      }

      new TomSelect(el, this.settings)
    })
  }
}
