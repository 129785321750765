import { Controller } from '@hotwired/stimulus'
import { German } from 'flatpickr/dist/l10n/de'

export default class extends Controller {
  static targets = ['items', 'types']


  connect() {
    this.itemsTarget.addEventListener('change', this.fetch_types)
  }

  fetch_types = () => {
    fetch('/items/get_types?item_id=' + this.itemsTarget.value)
      .then((response) => response.json())
      .then((data) =>
    {
      this.typesTarget.value = data
      this.typesTarget.dispatchEvent(new Event('change'))
    })
  }
}
