import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (!this.hasConsent()) {
      this.showBanner();
    }
  }

  hasConsent() {
    return document.cookie.includes('cookieConsent=true');
  }

  setConsent() {
    document.cookie = 'cookieConsent=true; expires=' + this.getCookieExpirationDate() + '; path=/';
    this.hideBanner();
  }

  getCookieExpirationDate() {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    return expirationDate.toUTCString();
  }

  showBanner() {
    this.element.innerHTML = `
      <div class="cookie-consent-banner">
        Diese Webseite verwendet Cookies, um Ihnen ein optimales Erlebnis zu bieten. Durch die Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. <br>Weitere Informationen finden Sie in unserer Datenschutzerklärung. 
        <div class="mt-3"><button data-action="click->cookie-consent#setConsent" class="btn btn-outline-primary mb-3">Verstanden</button></div>
      </div>
    `;
  }

  hideBanner() {
    this.element.innerHTML = '';
  }
}
