$(document).on('turbo:load', function () {
  if ($("#booking_anonymous_booking_attributes_name").val() != '' && $("#anonymousFieldsSwitch").length && $("#booking_anonymous_booking_attributes_name").length){
    $("#anonymousFieldsSwitch").prop('checked', true);
    $("#anonymousFields").show();
    $("#userField").hide();
  };

  $("#anonymousFieldsSwitch").click(function(){
    if ($(this).prop("checked")) {
          $("#anonymousFields").show(100);
          $("#userField").hide(100);
          document.getElementById("confirmation_comment").style.display = "block"

    } else {
      if ($("#booking_anonymous_booking_attributes_name").val() != ''){
        var r = confirm("Sollen die Eingaben gelöscht werden?");
        if (r == true) {
          $("#booking_anonymous_booking_attributes_name").val("");
          $("#booking_anonymous_booking_attributes_phone").val("");
          $("#booking_anonymous_booking_attributes_email").val("");
          $("#anonymousFields").hide(100);
          $("#userField").show(100);

        }
      } else {
        $("#anonymousFields").hide(100);
        $("#userField").show(100);
        document.getElementById("confirmation_comment").style.display = "none"
      }
    }
  });
})

function show_confirmation_comment_field(current_user) {
  selected_user = document.getElementById("booking_user_id").value
  if (selected_user != current_user) {
    $('#confirmation_comment').show()
  } else {
    $('#confirmation_comment').hide()
  }
}

var empty_image = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg=="
