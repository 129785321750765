import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { timeRemaining: Number };

  connect() {
    this.startCountdown();
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  }

  disconnect() {
    clearInterval(this.intervalId);
  }

  updateCountdown() {
    if (this.timeRemainingValue > 0) {
      const minutes = Math.floor(this.timeRemainingValue / 60);
      const seconds = Math.floor(this.timeRemainingValue % 60);
      const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

      this.element.textContent = formattedTime;

      this.timeRemainingValue -= 1;
    } else {
      clearInterval(this.intervalId);
      this.element.textContent = ''
    }
  }
}
